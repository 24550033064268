var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ModuleWithProviders } from '@angular/core';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';
import { CookieService } from 'ngx-cookie-service';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { TokenInterceptor } from '../token-interceptor';
import { HeadersInterceptor } from '../headers-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IntakeFlowService } from './utils/intake-flow.service';
import { SurveyDataService } from './utils/survey-data.service';
import { RiskCalcService } from './utils/risk-calc.service';
import { OrgService, CaseService } from './data';
import { EmployerInformationService } from '../pages/intake/employer-information/employer-information.service';
import { EmployerInformationResolver } from '../pages/intake/employer-information/employer-information.resolver';
import { environment } from '../../environments/environment';
import { ModalService } from './utils/modal.service';
import { SsoAuthGuardService, OnlySsoAuthGuardService } from './utils/sso-auth-guard.service';
import { userRoutes } from './data/api/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var NbSimpleRoleProvider = /** @class */ (function (_super) {
    __extends(NbSimpleRoleProvider, _super);
    function NbSimpleRoleProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NbSimpleRoleProvider.prototype.getRole = function () {
        // here you could provide any role based on any auth flow
        return observableOf('guest');
    };
    return NbSimpleRoleProvider;
}(NbRoleProvider));
export { NbSimpleRoleProvider };
export function tokenGetter(module, res, options) {
    var tokenData = __assign({}, res.body, { retrievalDate: new Date().toISOString() });
    window.localStorage.setItem('token_data', JSON.stringify(tokenData));
    return tokenData.accessToken;
}
/**
 * This is a workaround for nebular which can not handle multiple instances of the same auth strategy
 */
var NbCredentialAuthStrategy = /** @class */ (function (_super) {
    __extends(NbCredentialAuthStrategy, _super);
    function NbCredentialAuthStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NbCredentialAuthStrategy.setup = function (options) {
        return [NbCredentialAuthStrategy, options]; // HERE we make sure our strategy return correct class reference
    };
    NbCredentialAuthStrategy.ngInjectableDef = i0.defineInjectable({ factory: function NbCredentialAuthStrategy_Factory() { return new NbCredentialAuthStrategy(i0.inject(i1.HttpClient), i0.inject(i2.ActivatedRoute)); }, token: NbCredentialAuthStrategy, providedIn: "root" });
    return NbCredentialAuthStrategy;
}(NbPasswordAuthStrategy));
export { NbCredentialAuthStrategy };
var NbSsoAuthStrategy = /** @class */ (function (_super) {
    __extends(NbSsoAuthStrategy, _super);
    function NbSsoAuthStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NbSsoAuthStrategy.setup = function (options) {
        return [NbSsoAuthStrategy, options]; // HERE we make sure our strategy return correct class reference
    };
    NbSsoAuthStrategy.ngInjectableDef = i0.defineInjectable({ factory: function NbSsoAuthStrategy_Factory() { return new NbSsoAuthStrategy(i0.inject(i1.HttpClient), i0.inject(i2.ActivatedRoute)); }, token: NbSsoAuthStrategy, providedIn: "root" });
    return NbSsoAuthStrategy;
}(NbPasswordAuthStrategy));
export { NbSsoAuthStrategy };
// Demo Salesforce provider
var NbSso1AuthStrategy = /** @class */ (function (_super) {
    __extends(NbSso1AuthStrategy, _super);
    function NbSso1AuthStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NbSso1AuthStrategy.setup = function (options) {
        return [NbSso1AuthStrategy, options]; // HERE we make sure our strategy return correct class reference
    };
    NbSso1AuthStrategy.ngInjectableDef = i0.defineInjectable({ factory: function NbSso1AuthStrategy_Factory() { return new NbSso1AuthStrategy(i0.inject(i1.HttpClient), i0.inject(i2.ActivatedRoute)); }, token: NbSso1AuthStrategy, providedIn: "root" });
    return NbSso1AuthStrategy;
}(NbPasswordAuthStrategy));
export { NbSso1AuthStrategy };
export var NB_CORE_PROVIDERS = DataModule.forRoot().providers.concat(NbAuthModule.forRoot({
    strategies: [
        NbSso1AuthStrategy.setup({
            name: 'sso1',
            baseEndpoint: environment.basePath,
            login: {
                endpoint: '/authenticate/salesforce',
                redirect: {
                    success: '/pages/dashboard',
                    failure: null,
                },
                defaultErrors: ['The SSO provider failed to authenticate successfully.'],
            },
            logout: {
                endpoint: '/users/logout',
                method: 'post',
                redirect: {
                    success: '/auth/login',
                    failure: null,
                },
            },
            errors: {
                key: 'errors',
            },
            messages: {
                key: 'errors',
            },
            token: {
                key: 'id',
            },
        }),
        NbCredentialAuthStrategy.setup({
            name: 'email',
            baseEndpoint: environment.basePath,
            login: {
                endpoint: userRoutes.postLogin,
                redirect: {
                    success: '/pages/dashboard',
                    failure: null,
                },
                defaultErrors: ['The email or password that you entered is incorrect. Please contact 800-344-4222 for assistance'],
            },
            register: {
                endpoint: userRoutes.user,
                redirect: {
                    success: '/auth/verify-email',
                    failure: null,
                },
            },
            logout: {
                endpoint: userRoutes.getUserLogout,
                method: 'get',
                redirect: {
                    success: '/auth/login',
                    failure: null,
                },
            },
            requestPass: {
                endpoint: userRoutes.postUserForgotPassword,
                redirect: {
                    success: '/',
                    failure: null,
                },
                defaultMessages: ["Reset Password instructions sent. If you haven't received this\n          email within 5 minutes, please double-check the email entered or contact Concern at app@concernhealth.com"],
            },
            resetPass: {
                endpoint: userRoutes.postUserResetPassword,
                method: 'post',
                redirect: {
                    success: null,
                    failure: null,
                },
            },
            refreshToken: {
                endpoint: userRoutes.postRefreshToken,
                method: 'post',
                requireValidToken: true,
                redirect: {
                    success: null,
                    failure: '/auth/login'
                },
                defaultErrors: ['Something went wrong, please try again.'],
                defaultMessages: ['Your token has been successfully refreshed.'],
            },
            errors: {
                key: 'errors',
            },
            messages: {
                key: 'errors',
            },
            token: {
                class: NbAuthJWTToken,
                key: 'accessToken',
                getter: tokenGetter,
            },
        }),
    ],
    forms: {
        login: {
            redirectDelay: 0,
            rememberMe: false,
            showMessages: {
                success: true,
                error: true,
            },
        },
        validation: {
            password: {
                required: true,
                minLength: 8,
                maxLength: 50,
            },
            email: {
                required: true,
            },
            firstName: {
                required: true,
                minLength: 2,
                maxLength: 50,
            },
            lastName: {
                required: true,
                minLength: 2,
                maxLength: 50,
            },
        },
        requestPassword: {
            redirectDelay: 180000
        }
    },
}).providers, NbSecurityModule.forRoot({
    accessControl: {
        guest: {
            view: '*',
        },
        user: {
            parent: 'guest',
            create: '*',
            edit: '*',
            remove: '*',
        },
    },
}).providers, [
    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    NbCredentialAuthStrategy,
    NbSsoAuthStrategy,
    AnalyticsService,
    IntakeFlowService,
    CookieService,
    OrgService,
    CaseService,
    EmployerInformationService,
    EmployerInformationResolver,
    RiskCalcService,
    SurveyDataService,
    ModalService,
    SsoAuthGuardService,
    OnlySsoAuthGuardService,
    SurveyDataService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HeadersInterceptor,
        multi: true,
    },
]);
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
    CoreModule.forRoot = function () {
        return {
            ngModule: CoreModule,
            providers: NB_CORE_PROVIDERS.slice(),
        };
    };
    return CoreModule;
}());
export { CoreModule };
